import React, { FC } from "react";
import PipelineStreamRow from './PipelineStreamRow';
import { PipelineStream } from "../types/types";

type PipelineStreamsColProps = {
  streams: PipelineStream[];
};

function streamKey(stream: PipelineStream): string {
  return stream.id + stream.direction + stream.state;
}

const PipelineStreamsCol: FC<PipelineStreamsColProps> = ({ streams }) => {
  return (
    <div className="flex flex-col w-full space-y-2">
      {
        streams.map((s: PipelineStream) => {
          return <PipelineStreamRow stream={s} key={streamKey(s)} />;
        })
      }
    </div>
  )
}

export default PipelineStreamsCol;