import React, { FC } from "react";
import { PipelineStream } from "../types/types";
import PipelineStreamName from './PipelineStreamName';
import PipelineStreamState from './PipelineStreamState';

type PipelineStreamRowProps = {
  stream: PipelineStream;
  classes?: string;
};

const PipelineStreamRow: FC<PipelineStreamRowProps> = ({ stream, classes = "" }) => {
  return (
    <div className={"flex items-center border border-solid border-gray-200 rounded bg-white px-4 py-3 " + classes} >
      <PipelineStreamState state={stream.state} />
      <PipelineStreamName name={stream.url || "N/A"} />
    </div>
  );
};

export default PipelineStreamRow;