import { FC } from "react"
import Link from "./Link";

type DocumentationLinkProps = {
  href?: string;
  children?: React.ReactNode;
};

const DocumentationLink: FC<DocumentationLinkProps> = ({ href, children }) => {
  return (
    <Link href={href || "https://docs.transcoder.comprimato.com/docs/monitoring"}>{children ? children : <span>Documentation</span>}</Link>
  )
}

export default DocumentationLink