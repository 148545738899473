import React, { FC } from "react";

const PipelineOverviewCollapseButton: FC<{
  collapsed: boolean;
  onCollapseClicked: () => void;
}> = ({ collapsed, onCollapseClicked }) => {
  return (
      <button onClick={onCollapseClicked} className="flex justify-center items-center h-6 w-6 bg-brand-gray-200 rounded-full">
        {collapsed ?
          <div className="
            border-l-[5px] border-l-transparent
            border-t-[7px] border-t-brand-gray-500
            border-r-[5px] border-r-transparent">
          </div>
          :
          <div className="mb-0.5
            border-l-[5px] border-l-transparent
            border-b-[7px] border-b-brand-gray-500
            border-r-[5px] border-r-transparent">
          </div>
          }
      </button>
  );
};

export default PipelineOverviewCollapseButton;