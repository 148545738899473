import React, { FC, useState } from "react";
import PipelineOverviewTop from "./PipelineOverviewTop";
import PipelineStreams from "./PipelineStreams";
import { Pipeline } from "../types/types";

type PipelineOverviewProps = {
  pipeline: Pipeline;
};

const PipelineOverview: FC<PipelineOverviewProps> = ({ pipeline }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <div className="border border-gray-300 rounded-md mb-1 bg-gray-100">
      <div className="mx-6">
      <PipelineOverviewTop pipeline={pipeline}
                           collapsed={collapsed} onCollapseClicked={() => setCollapsed(!collapsed)} />
      {!collapsed && <PipelineStreams streams={pipeline.streams} /> }
      </div>
    </div>
  );
};

export default PipelineOverview;
