import React, { FC } from "react";

const CheckBox: FC<{
  on: boolean,
  className?: string
}> = ({on, className}) => {
  const dotColor = on ? "#0D6D4F" : "#D9D9D9";
  return (
    <span className={className}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="3.5" stroke={dotColor} fill={dotColor}/>
      </svg>
    </span>
  );
};

const PipelineAutoreconfiguration: FC<{
  autoreconfiguration: boolean
}> = ({ autoreconfiguration }) => {
  return (
    <span className="flex items-center">
      <CheckBox on={autoreconfiguration} />
      <span className="ml-2 text-xs text-brand-gray-700">Auto-reconfiguration</span>
    </span>
  )
}

export default PipelineAutoreconfiguration;