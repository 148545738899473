import React, { FC } from "react";
import { PipelineStream } from "../types/types";
import PipelineStreamsColDivider from './PipelineStreamsColDivider';
import PipelineStreamsCol from './PipelineStreamsCol';
import { getInputStreams, getOutputStreams } from "../utilities/pipeline";

type PipelineStreamsProps = {
  streams: PipelineStream[];
};

const PipelineStreams: FC<PipelineStreamsProps> = ({ streams }) => {
  const inputStreams = getInputStreams(streams);
  const outputStreams = getOutputStreams(streams);

  return (
    <div className="flex pb-3">
      <PipelineStreamsCol streams={inputStreams} />
      <PipelineStreamsColDivider />
      <PipelineStreamsCol streams={outputStreams} />
    </div>
  );
};

export default PipelineStreams;
