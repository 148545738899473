import { FC } from "react"

type HintTextProps = {
  heading: string;
  children?: React.ReactNode;
};

const HintText: FC<HintTextProps> = ({ heading, children }) => {
  return (
    <div className="flex flex-col justify-center h-4/6 text-center">
      <h1 className="font-center-light text-3xl">{heading}</h1>
      <div className="mt-4 flex justify-center">
        <p className="w-7/12">
          {children}
        </p>
      </div>
    </div>
  )
}

export default HintText