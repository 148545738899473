import React, { FC } from "react";
import PipelineState from "../types/PipelineStateEnum";

type PipelineStateProps = {
  inputState: PipelineState;
  outputState: PipelineState;
};

enum BiscuitType {
  In, Out
};

type BiscuitProps = {
  biscuitType: BiscuitType;
  classNames: string;
};

const Biscuit: FC<BiscuitProps> = ({ biscuitType, classNames }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 24" className={"h-6 " + classNames + (biscuitType === BiscuitType.In ? " mr-0.5" : "")}>
      {
        biscuitType === BiscuitType.In ?
          <circle cx="100%" cy="50%" r="50%"/>
          :
          <circle cx="0%" cy="50%" r="50%" />
      }
    </svg>
  );
};

type OutStateBiscuitProps = {
  classNames: string;
};

const OutStateBiscuit: FC<OutStateBiscuitProps> = ({ classNames }) => {
  return (
    <Biscuit biscuitType={BiscuitType.Out} classNames={classNames} />
  );
};

type InStateBiscuitProps = {
  classNames: string;
};

const InStateBiscuit: FC<InStateBiscuitProps> = ({ classNames }) => {
  return (
    <Biscuit biscuitType={BiscuitType.In} classNames={classNames} />
  );
};

function getStateClassName(state: PipelineState): string {
  switch (state) {
    case PipelineState.Stopped:
      return "fill-state-stopped-fg";
    case PipelineState.Ok:
      return "fill-state-ok-fg";
    case PipelineState.Warning:
      return "fill-state-warning-fg";
    case PipelineState.Error:
      return "fill-state-error-fg";
    case PipelineState.Failed:
      return "fill-state-fail-fg";
    default:
      return "";
  }
}

const PipelineStateBiscuit: FC<PipelineStateProps> = ({ inputState, outputState }) => {
  const inStateClassName = getStateClassName(inputState);
  const outStateClassName = getStateClassName(outputState);

  return (
    <span className="flex">
      <InStateBiscuit classNames={`${inStateClassName}`} />
      <OutStateBiscuit classNames={`${outStateClassName}`} />
    </span>
  );
};

export default PipelineStateBiscuit;
