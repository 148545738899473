import * as PipelineState from "../types/PipelineStateEnum";
import { ApiResponse, Pipeline, PipelineStateInstantResult, PipelineStateRangeResult, PipelineStateResult, PipelineStream, timestamp } from "../types/types";
import { PipelineStateHistoryBuilder } from "./PipelineStateHistoryBuilder";
import groupBy from "../utilities/groupBy";
import { getInputStreams, getOutputStreams } from "../utilities/pipeline";

const apiUrl: string | undefined = process.env.REACT_APP_API_URL;
if (apiUrl === undefined) {
  throw Error("API URL is undefined");
}

const apiVersionPrefix = "/v1"
const apiPipelinesCurrentStateRoute = `${apiVersionPrefix}/pipelines-current-state`
const apiPipelinesStateHistoryRoute = `${apiVersionPrefix}/pipelines-state-history`
const apiPipelinesStateRoute = `${apiVersionPrefix}/pipelines-state`

export async function loadPipelines(groupId?: string): Promise<Pipeline[]> {
  if (!groupId) {
    return [];
  }
  const currentState = await queryPipelinesCurrentState(groupId);
  return parsePipelines(currentState);
}

export async function loadPipelinesWithHistory(groupId?: string): Promise<Pipeline[]> {
  if (!groupId) {
    return [];
  }
  const [currentState, history] = await Promise.all([
    queryPipelinesCurrentState(groupId),
    queryPipelinesStateHistory(groupId)
  ]);
  // get last time slot timestamps from the query responses to align the merged results
  const lastTimeSlotStart = PipelineStateHistoryBuilder.getLastTimestamp(history);
  const lastTimeSlotEnd = PipelineStateHistoryBuilder.getTimestamp(currentState);
  const lastTimeSlotState = await queryPipelinesState(groupId, lastTimeSlotStart, lastTimeSlotEnd)
  const pipelines = parsePipelines(currentState);
  const stateHistoryBuilder = new PipelineStateHistoryBuilder(history,
    lastTimeSlotState === undefined ? [] : lastTimeSlotState,
    buildPipelineId
  );
  pipelines.forEach(
    pipeline => pipeline.history = stateHistoryBuilder.build(pipeline.id)
  );
  return pipelines;
}

/**
 * Query is used to render the pipeline state biscuit, all its metadata (name, hostname, autoreconfiguration, ...),
 * pipeline stream states and their metadata.
 *
 * @param groupId monitoring group id value
 * @return all pipelines and their streams current states (including labels holding the metadata)
 */
async function queryPipelinesCurrentState(groupId?: string): Promise<PipelineStateInstantResult[]> {
  return query<PipelineStateInstantResult[]>(apiPipelinesCurrentStateRoute, groupId)
      .then((response) => response.data.result);
}

/**
 * The query is used to render the pipeline history chart.
 *
 * Note that the result includes just the past time intervals e.g. for granularity 1m at 12:23:45 will return history
 * till 12:23:00 - last record contains state value aggregated for the interval [12:22:00,12:23:00].
 *
 * To render the complete chart this query result has to be combined with the result of the queryPipelinesState()
 * for the currently running time interval i.e. [12:23:00,12:23:45]
 *
 * @param groupId monitoring group id
 * @return history of the pipelines and their streams
 */
async function queryPipelinesStateHistory(groupId?: string): Promise<PipelineStateRangeResult[]> {
  // or vector() -> add a result item containing all timestamps to be able to fill in missing timestamps to real result items
  return query<PipelineStateRangeResult[]>(apiPipelinesStateHistoryRoute, groupId)
      .then((response) => response.data.result);
}

/**
 * The query is used to render the last (most up-to-date) time interval of the pipeline state history chart.
 * Its result is combined with the queryPipelinesStateHistory() call result to provide data for the chart.
 *
 * For details see queryPipelinesStateHistory() description.
 *
 * @param groupId monitoring group id
 * @param from start of the time interval
 * @param to end of the time interval
 * @return pipelines and their streams states aggregated for the [from,to] time interval
 */
async function queryPipelinesState(groupId?: string, from?: timestamp, to?:timestamp): Promise<PipelineStateInstantResult[] | undefined> {
  if (from === undefined || to === undefined) {
    return undefined;
  }
  return query<PipelineStateInstantResult[]>(apiPipelinesStateRoute, groupId, {
    monitoringGroupId: groupId,
    fromEpochSeconds: from,
    toEpochSeconds: to,
  })
      .then((response) => response.data.result);
}

async function query<ResultType>(path: string, monitoringGroupId?: string, body?: object): Promise<ApiResponse<ResultType>> {
  const response = await fetch(`${apiUrl}${path}`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: (body && JSON.stringify(body)) || JSON.stringify({ monitoringGroupId }),
  });
  const json = await response.json();
  if (json.status !== "success") {
    throw Error("non-success query status: " + json.status);
  }
  return json;
}

function parsePipelines(currentStateResults: PipelineStateInstantResult[]): Pipeline[]
{
  const validResponses = getValidPipelineStates(currentStateResults);
  const pipelineIdToPipeline = groupBy(validResponses, buildPipelineId);
  const pipelineArray: Pipeline[] = [];
  // forEach callback takes (value, key)
  pipelineIdToPipeline.forEach((pipelines, pipelineId) => {
    const streams: PipelineStream[] = pipelines
      .filter((pipeline: PipelineStateInstantResult) => pipeline.metric.transcoder_pipeline_stream_id !== undefined)
      .map((pipeline: PipelineStateInstantResult) => {
        return {
          id: pipeline.metric.transcoder_pipeline_stream_id!,
          direction: pipeline.metric.transcoder_pipeline_stream_direction || undefined,
          url: pipeline.metric.transcoder_pipeline_stream_url,
          state: PipelineState.getPipelineStateByStrValue(pipeline.value[1]),
        }
      });
    const inputStreams: PipelineStream[] = getInputStreams(streams);
    const outputStreams: PipelineStream[] = getOutputStreams(streams);

    const pipeline:Pipeline[] = pipelines
      .filter((pipeline: PipelineStateInstantResult) => pipeline.metric.transcoder_pipeline_stream_id === undefined)
      .map(function (pipeline: PipelineStateInstantResult):Pipeline {
        const pipelineState = PipelineState.getPipelineStateByStrValue(pipeline.value[1]);
        return {
          id: pipelineId,
          name: pipeline.metric.transcoder_pipeline_name,
          hostname: pipeline.metric.transcoder_hostname,
          autoreconfiguration: JSON.parse(pipeline.metric.transcoder_pipeline_autoreconfiguration),
          inputState: resolvePipelineState(pipelineState, inputStreams),
          outputState: resolvePipelineState(pipelineState, outputStreams),
          streams: resolveStreamStates(streams, pipelineState),
        };
      });
    if(pipeline.length === 0) {
      console.log(`No pipeline records found for pipeline id: ${pipelineId}, ignoring the orphan stream records.`);
      return;
    }
    else if(pipeline.length > 1) {
      console.log(`Multiple (${pipeline.length}) pipeline records found for pipeline id: ${pipelineId}, picking the 1st one.`);
    }
    pipelineArray.push(pipeline[0]);
  });

  return pipelineArray;
}

function getValidPipelineStates(pipelines: PipelineStateInstantResult[]): PipelineStateInstantResult[] {
  return pipelines.filter(response =>
    parseInt(response.value[1]) !== PipelineState.PipelineState.Removed.valueOf() // not Removed
    // TODO can transcoder always provide the value?
    && response.metric.transcoder_pipeline_unique_id !== undefined // having valid unique pipeline id
  );
}

function buildPipelineId(pipeline: PipelineStateResult): string {
  const instanceId = pipeline.metric.transcoder_instance_id || "unknown-instance-id"
  return `${instanceId}:${pipeline.metric.transcoder_pipeline_unique_id}`
}

function resolvePipelineState(
  pipelineState: PipelineState.PipelineState,
  streams: PipelineStream[]
): PipelineState.PipelineState {
  return streams.length === 0 ?
    PipelineState.PipelineState.Stopped : // to emulate transcoder ui behavior: no streams -> stopped
    streams.reduce(
      (state,stream) => PipelineState.getWorse(state, stream.state),
      pipelineState // pipeline state as the initial value for the reduce
    );
}

function resolveStreamStates(
  streams: PipelineStream[],
  pipelineState: PipelineState.PipelineState
): PipelineStream[] {
  return streams.map(
    (stream) => {
      const resolvedStream = { ...stream }; // make a stream copy
      resolvedStream.state = PipelineState.getWorse(stream.state, pipelineState)
      return resolvedStream;
    }
  );
}
