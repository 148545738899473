import React, {FC} from "react";

import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {PipelineStateHistory} from "../types/types";
import { PipelineState, getPipelineStateName } from "../types/PipelineStateEnum";

// TODO import color constants from the tailwind.config.js directly
const stateColors = new Map([
  [ PipelineState.Ok, '#0E7D5B' ],
  [ PipelineState.Stopped, '#BBBBBB' ],
  [ PipelineState.Warning, '#DBBA40' ],
  [ PipelineState.Error, '#D22626' ],
  [ PipelineState.Failed, '#740E0E' ],
]);

function createChartData(history: PipelineStateHistory): any[] {
  const historyArray = Array.from(history);
  historyArray.sort( // sort by timestamps ascending -> the youngest on the right side of the chart
    (item1, item2) => item1[0] - item2[0]
  );
  return historyArray.map((item) => createChartDataItem(item[0], item[1]));
}

function createChartDataItem(timestamp: number, state: PipelineState): any {
  return {
    // toString() helps with the last (i.e. latest, current) bar having the same width as the others
    // this works although in ApexOptions, xaxis type is numeric, ¯\_(ツ)_/¯
    x: timestamp.toString(),
    y: state !== PipelineState.Removed ? 1 : 0, // fixed value for fixed size of the history boxes (bars)
    state: state, // store state value for the tooltip generation
    fillColor: stateColors.get(state)
  };
}

const PipelineStateHistoryChart: FC<{
  history: PipelineStateHistory,
  width?: number,
  height?: number
}> = ({ history, width, height}) => {
  const options: ApexOptions = {
    chart: {
      animations: { enabled: false },
      sparkline: { enabled: true }
    },
    xaxis: {
      type: "numeric",
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false }
    },
    dataLabels: { enabled: false },
    grid: { show: false },
    yaxis: { show: false },
    plotOptions: {
      bar: {
        columnWidth: 6,
      }
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        /**
         * @param timestamp String representation of the timestamp in milliseconds, e.g. "1690961940000".
         * Type is number because it is defined so in apexcharts.d.ts, but the actual value is string, see {@link createChartDataItem}.
         * @param ignored
         * @returns A datetime string to display at the top of the tooltip. 
         */
        formatter: (timestamp: number, ignored) => {
          const timestampStr = timestamp as unknown as string;
          const timestampNum = parseInt(timestampStr);
          const d = new Date(timestampNum * 1000);
          return d.toLocaleTimeString() + " " + d.toLocaleDateString();
        }
      },
      y: {
        /**
         * @param ignored
         * @param param1 Object containing series data and other metadata.
         * @returns Name of the pipeline state according the the data point, e.g. "Stopped", "Error", etc.
         */
        formatter: (ignored: number, {series, seriesIndex, dataPointIndex, w}) => {
          return getPipelineStateName(w.config.series[seriesIndex].data[dataPointIndex].state);
        }
      }
    }
  };
  const series = [{
    // What is displayed next to the pipeline state name in the tooltip, if removed, there will be 'series-1' 
    name: '',
    data: createChartData(history)
  }];
  return (
      <Chart className="flex"
             options={options}
             series={series}
             type="bar"
             width={width} height={height}
      />
  )
}

export default PipelineStateHistoryChart;