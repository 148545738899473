import React, { FC } from "react";

type PipelineStreamNameProps = {
  name: string;
}

const PipelineStreamName: FC<PipelineStreamNameProps> = ({ name }) => {
  return (
    <span className="text-base text-brand-gray-700">{name}</span>
  )
}

export default PipelineStreamName;