import React, { FC } from "react";
import PipelineName from "./PipelineName";
import PipelineAutoreconfiguration from "./PipelineAutoreconfiguration";
import PipelineStateBiscuit from "./PipelineStateBiscuit";
import PipelineOverviewCollapseButton from "./PipelineOverviewCollapseButton";
import PipelineStateHistoryChart from "./PipelineStateHistoryChart";
import {Pipeline} from "../types/types";

type PipelineOverviewTopProps = {
  pipeline: Pipeline;
  collapsed: boolean;
  onCollapseClicked: () => void;
};

const PipelineOverviewTop: FC<PipelineOverviewTopProps> = ({
    pipeline,
    collapsed, onCollapseClicked
}) => {
  return (
    <div className="grid grid-cols-2 my-4">
      <div className="flex items-center">
        <PipelineStateBiscuit inputState={pipeline.inputState} outputState={pipeline.outputState} />
        <div className="flex items-center grow">
          <PipelineName name={pipeline.name} />
          <div className="flex items-center grow justify-between mr-3">
            <div className="ml-12 text-xs">Hostname: {pipeline.hostname}</div>
            <PipelineAutoreconfiguration autoreconfiguration={pipeline.autoreconfiguration} />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex grow ml-3 items-center">
          <span className="mr-6 text-xs text-brand-gray-700">Pipeline health:</span>
          <PipelineStateHistoryChart history={pipeline.history || new Map()} width={400} height={20} />
        </div>
        <PipelineOverviewCollapseButton collapsed={collapsed} onCollapseClicked={onCollapseClicked} />
      </div>
    </div>
  );
};

export default PipelineOverviewTop;