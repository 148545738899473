import React, { FC } from "react";
import PipelineState from "../types/PipelineStateEnum";

type PipelineStreamStateProps = {
  state: PipelineState;
}

type StateData = {
  displayName: string;
  classNames: string;
}

const stoppedState: StateData = {
  displayName: "Stopped",
  classNames: "border-state-stopped-fg text-state-stopped-fg bg-state-stopped-bg"
};

const okState: StateData = {
  displayName: "Ok",
  classNames: "border-state-ok-fg text-state-ok-fg bg-state-ok-bg"
};

const warningState: StateData = {
  displayName: "Warning",
  classNames: "border-state-warning-fg text-state-warning-fg bg-state-warning-bg"
};

const errorState: StateData = {
  displayName: "Error",
  classNames: "border-state-error-fg text-state-error-fg bg-state-error-bg"
};

const failedState: StateData = {
  displayName: "Failed",
  classNames: "border-state-failed-fg text-state-failed-fg bg-state-failed-bg"
};

function getStateData(state: PipelineState): StateData {
  switch (state) {
    case PipelineState.Stopped:
      return stoppedState;
    case PipelineState.Ok:
      return okState;
    case PipelineState.Warning:
      return warningState;
    case PipelineState.Error:
      return errorState;
    case PipelineState.Failed:
      return failedState;
    default:
      return {
        displayName: "",
        classNames: ""
      };
  }
}

const PipelineStreamState: FC<PipelineStreamStateProps> = ({ state }) => {
  const stateData = getStateData(state);
  return (
    <button className={"flex mr-4 px-3 py-1 rounded-full text-xs  border border-1 border-solid " + stateData.classNames}>
      <p className="mr-1">{'\u23FA'}</p>
       <p>{stateData.displayName}</p>
       </button>
  )
}

export default PipelineStreamState;