import {Pipeline, PipelineStateHistory} from "../types/types";

export class PipelineStateHistoryCache {

  private constructor(private cache: Map<string, PipelineStateHistory>) {}

  static createEmpty(): PipelineStateHistoryCache {
    return new PipelineStateHistoryCache(new Map())
  }

  static create(pipelines: Pipeline[]): PipelineStateHistoryCache {
    const cache = pipelines.reduce((map, pipeline) => {
      map.set(pipeline.id, pipeline.history);
      return map;
    }, new Map());
    return new PipelineStateHistoryCache(cache)
  }

  fillInHistory(pipelines: Pipeline[]): void {
    pipelines.forEach((pipeline) => {
      const history = this.cache.get(pipeline.id) || new Map();
      pipeline.history = history;
    });
  }
}
