import {FC} from "react"

const LoaderLogo: FC = () => {
  return (
      <div className="flex flex-col justify-center h-4/6">
        <img
            className="h-6 self-center"
            src="/cmpto_logo.gif"
            width={140}
            height={24}
            alt="Comprimato Logo Loader"
        />
      </div>
  )
}

export default LoaderLogo;
