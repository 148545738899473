export enum PipelineState {
  Ok = 1,
  Stopped = 0,
  Warning = -1,
  Error = -2,
  Failed = -3,
  Removed = 66,
}

export default PipelineState;

export function getPipelineState(numState: number): PipelineState {
  switch (numState) {
      case 0:
          return PipelineState.Stopped;
      case 1:
          return PipelineState.Ok;
      case -1:
          return PipelineState.Warning;
      case -2:
          return PipelineState.Error;
      case -3:
          return PipelineState.Failed;
      case 66:
          return PipelineState.Removed;
      default:
          throw new Error(`Unknown stream state: ${numState}`);
  }
}

export function getPipelineStateByStrValue(numState: string): PipelineState {
  return getPipelineState(parseInt(numState));
}

export function getWorse(s1: PipelineState, s2: PipelineState) : PipelineState {
  return s1.valueOf() <= s2.valueOf() ? s1 : s2;
}

export function compare(state1: PipelineState, state2: PipelineState): number {
  return state1.valueOf() - state2.valueOf();
}

const objectValues = Object.values(PipelineState);
const objectKeys = Object.keys(PipelineState);
export function getPipelineStateName(value: PipelineState) {
  const indexOfValue = objectValues.indexOf(value);
  return objectKeys[indexOfValue];
}