import { FC, useState } from "react";
import PipelineOverview from "./PipelineOverview";
import DocumentationLink from "./DocumentationLink";
import SupportLink from "./SupportLink";
import ChartBarIcon from "./ChartBarIcon";
import { Pipeline } from "../types/types";
import useInterval from "../utilities/useInterval";
import { loadPipelines, loadPipelinesWithHistory } from "../api/api";
import Link from "./Link";
import HintText from "./HintText";
import TopBar from "./TopBar";
import { PipelineStateHistoryCache } from "../api/PipelineStateHistoryCache";
import LoaderLogo from "./LoaderLogo";

type AllPipelinesOverviewProps = {
  monitoringGroupId?: string;
}

const historyRefreshIntervalSeconds: number = parseInt(process.env.REACT_APP_HISTORY_REFRESH_INTERVAL_SECONDS || "5");

const AllPipelinesOverview: FC<AllPipelinesOverviewProps> = ({ monitoringGroupId }) => {
  const [pipelines, setPipelines] = useState<Pipeline[] | undefined>(undefined);
  const [pipelineHistories, setPipelineHistories] =
    useState<PipelineStateHistoryCache>(PipelineStateHistoryCache.createEmpty());

  useInterval(() => {
    const shouldLoadHistory = Math.floor(Date.now() / 1000) % historyRefreshIntervalSeconds === 0;
    if (shouldLoadHistory) {
      loadPipelinesWithHistory(monitoringGroupId).then(data => {
        setPipelineHistories(PipelineStateHistoryCache.create(data));
        setPipelines(data);
      })
        .catch((err) => console.log(err));
    } else {
      loadPipelines(monitoringGroupId).then((data) => {
          pipelineHistories.fillInHistory(data);
          setPipelines(data);
      })
        .catch((err) => console.log(err));
    }
  }, 1000); // refresh interval in [ms]

  const pipelineItems = pipelines !== undefined ?
      pipelines.map((pipeline) => <PipelineOverview pipeline={pipeline} key={pipeline.id} />)
      : [];

  return (
    <div className="mx-3 h-full overflow-hidden">
      <TopBar>
        Pipeline Monitoring <sup className="text-brand-violet-400">Beta</sup>
      </TopBar>
      {
        monitoringGroupId ?
          (
              pipelines !== undefined ?
                (
                  // Pipelines loaded from API
                  pipelineItems.length > 0 ?
                    // API returned at least one pipeline
                    <div className="mt-0">{pipelineItems}</div>
                    :
                    // API returned no pipelines
                    <HintText heading="No pipelines are visible for the selected Monitoring Group ID">
                      If you're running pipelines in the Transcoder but they are not showing up here, it could be due to monitoring being disabled on these instances. For more information or help, please visit our <DocumentationLink href="https://docs.transcoder.comprimato.com/docs/monitoring-problems#no-pipelines-are-visible-for-the-selected-monitoring-group-id" /> or contact our <SupportLink />.
                    </HintText>
                )
                :
                // Pipelines not yet loaded from API, show loader
                <LoaderLogo />
          )
          :
          <HintText heading="No Monitoring Group ID has been selected for monitoring">
            Please proceed to the <Link href="https://transcoder.comprimato.com/app/licenses">Customer Dashboard</Link>, identify the relevant license, and click on the Monitoring Dashboard icon (<ChartBarIcon />). For further assistance, feel free to consult our <DocumentationLink href="https://docs.transcoder.comprimato.com/docs/monitoring-problems#no-monitoring-group-id-has-been-selected-for-monitoring" /> or contact our <SupportLink />.
          </HintText>
      }
    </div>
  );
};
export default AllPipelinesOverview;
