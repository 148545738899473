import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AllPipelinesOverview from "./components/AllPipelinesOverview";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const monitoringGroupId = new URLSearchParams(window.location.search).get("monitoring_group_id") || undefined;

root.render(
  <React.StrictMode>
    <AllPipelinesOverview monitoringGroupId={monitoringGroupId} />
  </React.StrictMode>
);