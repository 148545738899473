import * as PipelineState from "../types/PipelineStateEnum";
import {
  PipelineStateHistory,
  PipelineStateInstantResult,
  PipelineStateRangeResult,
  PipelineStateResult,
  timestamp
} from "../types/types";

export class PipelineStateHistoryBuilder {

  private lastTimeSlotResults: PipelineStateInstantResult[];
  private allTimestamps: timestamp[];
  private history: PipelineStateHistory = new Map<number,PipelineState.PipelineState>();

  constructor(
    private historyResults: PipelineStateRangeResult[],
    lastTimeSlotResults: PipelineStateInstantResult[],
    private pipelineIdBuilder: (state: PipelineStateResult) => string
  ) {
    this.lastTimeSlotResults = lastTimeSlotResults;
    this.allTimestamps = PipelineStateHistoryBuilder.getAllTimestamps(historyResults);
    const lastSlotTimestamp = PipelineStateHistoryBuilder.getTimestamp(lastTimeSlotResults);
    if (lastSlotTimestamp !== undefined) {
      this.allTimestamps.push(lastSlotTimestamp);
    }
  }

  static getAllTimestamps(historyResult: PipelineStateRangeResult[]): timestamp[] {
    // all timestamps result item has no labels (see 'or vector()' part of the PromQL query)
    const timestampsItem = historyResult.find(
      (item) => item.metric.transcoder_pipeline_id === undefined
    );
    if(timestampsItem === undefined) {
      throw new Error("History query result does not contain all timestamps item (no labels).")
    }
    return timestampsItem.values.map(
      (value) => parseInt(value[0])
    );
  }

  static getTimestamp(currentState: PipelineStateInstantResult[]): timestamp | undefined {
    return currentState.length < 1 ? undefined : parseInt(currentState[0].value[0]);
  }

  static getLastTimestamp(history: PipelineStateRangeResult[]): timestamp | undefined {
    const allSlots = PipelineStateHistoryBuilder.getAllTimestamps(history);
    allSlots.sort().reverse(); // descending -> youngest first
    return allSlots.length > 1 ? allSlots[0] : undefined;
  }

  build(pipelineId: string): PipelineStateHistory {
    this.history = new Map();
    this.historyResults.filter(item => this.pipelineIdBuilder(item) === pipelineId)
      .forEach((item) => this.add(item.values));
    this.lastTimeSlotResults.filter(item => this.pipelineIdBuilder(item) === pipelineId)
      .forEach((item) => this.addItem(item.value));
    this.fillInMissingTimestamps();
    return this.history;
  }

  add(history: [[string, string]]) {
    history.forEach(item => this.addItem(item));
  }

  addItem(historyItem: [string, string]) {
    const state = PipelineState.getPipelineState(parseInt(historyItem[1]));
    if(state === PipelineState.PipelineState.Removed) {
      return;
    }
    const timestamp = parseInt(historyItem[0]);
    const previous = this.history.get(timestamp);
    if(previous === undefined
      || PipelineState.compare(previous, state) > 0 //  pick the worst one (min)
    ) {
      this.history.set(timestamp, state);
    }
  }

  fillInMissingTimestamps() {
    this.allTimestamps.forEach(ts => {
      if(!this.history.has(ts)) {
        this.history.set(ts, PipelineState.PipelineState.Removed);
      }
    })
  }
}

export default PipelineStateHistoryBuilder;