import { FC } from "react"

type LinkProps = {
  href: string;
  children?: React.ReactNode;
};

const Link: FC<LinkProps> = ({href, children}) => {
  return (
    <a target="_blank" href={href} rel="noopener noreferrer" className="underline">{children}</a>
  )
}

export default Link