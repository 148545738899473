import React, { FC } from "react";

type PipelineNameProps = {
  name: string;
}

const PipelineName: FC<PipelineNameProps> = ({ name }) => {
  return (
    <span className="ml-2 text-xl text-brand-gray-700">{name}</span>
  )
}

export default PipelineName;