import { FC } from "react"

const Logo: FC = () => {
  return (
    <a href={`/${window.location.search}`}>
      <img
        className="h-6 w-auto"
        src="/cmpto_logo.svg"
        width={140}
        height={24}
        alt="Comprimato Logo"
      />
    </a>
  )
}

export default Logo