import { FC } from "react"
import Logo from "./Logo";
import DocumentationLink from "./DocumentationLink";
import InfoIcon from "./InfoIcon";

type TopBarProps = {
  children?: React.ReactNode;
};

const TopBar: FC<TopBarProps> = ({ children }) => {
  return (
    <div className="h-16 flex justify-between items-center content-center w-full">
      <Logo />
      <div className="flex">
        <h1 className="font-center-light text-xl">{children}</h1>
      </div>
      <div className="mr-5">
        <DocumentationLink><InfoIcon /></DocumentationLink>
      </div>
    </div>
  )
}

export default TopBar